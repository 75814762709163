<template>
    <defaultSec :title="'事件'">
        <keep-alive>
            <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="事件名称">
                    <el-input v-model="formParam.eventName" placeholder="请输入事件名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="formParam.eventRemark" placeholder="请输入备注" clearable></el-input>
                </el-form-item>
                <el-form-item label="事件组">
                    <el-select v-model="formParam.eventGroupId"  filterable clearable  placeholder="请选择事件组">
                        <el-option v-for="item in eventGroupList"
                                   :key="item.eventGroupId"
                                   :label="item.eventGroupName"
                                   :value="item.eventGroupId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="initList">查询</el-button>
                    <el-button type="success" @click="add">新建</el-button>
                </el-form-item>
            </el-form>
        </keep-alive>
        <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table :data="dataList" border size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column label="标识" prop="eventId" width="80"></el-table-column>
                <el-table-column label="事件组名称" prop="eventGroupName" width="190"></el-table-column>
                <el-table-column label="事件名称" prop="eventName" width="150"></el-table-column>
                <el-table-column label="表名称" prop="eventTableName" width="230" ></el-table-column>
                <el-table-column label="数据库" prop="eventDataBase" width="230" ></el-table-column>
                <el-table-column label="事件类型" prop="eventTypeName" width="230" ></el-table-column>
                <el-table-column label="备注" prop="eventRemark" width="230" ></el-table-column>
                <el-table-column label="排序" prop="sort" width="80"></el-table-column>
                <el-table-column label="创建时间" prop="createTime" width="200">
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="140">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="warning " size="mini"
                                       @click="toDetail(scope.row)">详情
                            </el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                formParam: {
                    pageSize: 15,
                    currentPage: 1,
                    eventName:'',
                    eventType:'',
                    status:'',
                    eventTableName:'',
                },
                dataList: [],
                eventGroupList:[],
                eventTypeList:[],
                propertyList:[],
                showDialog: false,
                form: {
                    eventId: '',
                    eventGroupId:'',
                    eventName:'',
                    eventTableName:'',
                    eventDataBase:'',
                    eventType:2,
                    eventImg:'',
                    writeTemplate:'',
                    readTemplate:'',
                    eventRemark:'',
                    sort:1
                },
                total: 0
            }
        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            this.initList()
            this.initEventGroupList()
            this.initEventTypeList()
        },
        methods: {
            initEventGroupList(){
                this.$apiGet('lechun-cms/event/getEventGroupList', this.formParam).then(res => {
                    console.log(res)
                    this.eventGroupList = res.list
                })
            },
            initEventTypeList(){
                this.$apiGet('lechun-cms/event/getEventTypeList', {}).then(res => {
                    console.log(res)
                    this.eventTypeList=res.value
                })
            },
            initList() {
                this.dataList=[]
                this.$apiGet('lechun-cms/event/getEventList', this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.dataList = res.list
                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            toDetail(row){
                this.$router.push({name: 'eventDetail',params:{eventId:row.eventId}})
            },
            add(){
                this.$router.push({name: 'eventDetail',params:{eventId:0}})
            },
            save(){
                if(this.form.eventGroupId==''){
                    this.$message({
                        showClose: true,
                        message: '事件分组必填',
                        type: 'error'
                    });
                    return;
                }
                if(this.form.eventTableName==''){
                    this.$message({
                        showClose: true,
                        message: '表名称名称必填',
                        type: 'error'
                    });
                    return;
                }
                if(this.form.eventDataBase==''){
                    this.$message({
                        showClose: true,
                        message: '数据库名称必填',
                        type: 'error'
                    });
                    return;
                }
                if(this.form.eventName==''){
                    this.$message({
                        showClose: true,
                        message: '事件名称必填',
                        type: 'error'
                    });
                    return;
                }
                if(this.form.eventType==1&&this.form.readTemplate==''){
                    this.$message({
                        showClose: true,
                        message: '读模板必填',
                        type: 'error'
                    });
                    return;
                }
                if(this.form.eventType==3&&this.form.writeTemplate==''){
                    this.$message({
                        showClose: true,
                        message: '写模板必填',
                        type: 'error'
                    });
                    return;
                }
                this.$apiGet('lechun-cms/event/saveEvent', this.form).then(res => {
                    console.log(res)
                    if(res.status==200){
                        this.$message({
                            message: '保存成功',
                            type: 'success'
                        });
                        this.initList()
                    }else {
                        this.$message({
                            showClose: true,
                            message: res.message,
                            type: 'error'
                        });
                    }
                })
            },
            getEventPropertyList(){
                this.$apiGet('lechun-cms/event/getEventGroupList', this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.tableList = res.list
                })
            },
            deleteEventProerty(eventPropertyId){
                this.$confirm('删除后不可撤销，确定删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/scrmproperty/deleteEventProperty', {eventPropertyId:eventPropertyId}).then(res => {
                        console.log(res)

                    })
                }).catch(() => {

                });
            }
        }
    }
</script>


<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>